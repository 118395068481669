.uk-table tbody tr {
    color: #818181;
    border-top: 1px solid #818181 !important;
    border-bottom: 1px solid #818181 !important;
}

tr>th.table-column-heading {
    color: #818181;
    font-family: 'GothicBold', 'Arial';
    line-height: normal;
}

.sutton {
    font-size: 16px;
    color: #FFFFFF;
}

.tech {
    font-size: 16px;
    color: #3E6AB3;
}

.dollar {
    font-size: 24px;
    color: #3E6AB3
}