.nav-wrapper .uk-navbar-nav>li>a {
    font-family: 'GothamBold', 'Arial';
    line-height: normal;
    font-size: 20px;
    text-transform: none;
}

.logo {
   color: #818181;
    text-align: center;
    margin: 0;
    font-size: 32px;
}

nav.uk-navbar {
    background-color: #3B3B3B;
    box-sizing: content-box;
    border-bottom: 2px solid #3E6AB3;
}

div.uk-navbar-dropdown {
    background-color: #3B3B3B;
    color: #818181;
}

div.uk-navbar-dropbar {
    background-color: #3B3B3B;
    color: #818181;
    border-bottom: 2px solid #3E6AB3;
}

.uk-navbar-dropdown-nav>li>a {
    color: #818181 !important;
    margin-bottom: 10px;
    font-size: 20px;;
}

.logo-tech {
    color: #3E6AB3;
    font-weight: 600;
}

.hamburger {
    color: #818181;
}

a>button.contact-button {
    background-color: #3E6AB3;
    border-radius: 20px;
    text-transform: none;
    font-size: 20px;
}