@font-face {
  font-family: 'GothicBold';
  src: local('GothicBold'),
    url('./assets/fonts/Gothic-Bold.woff') format('truetype');
}

@font-face {
  font-family: 'GothamBlack';
  src: local('GothamBlack'),
    url('./assets/fonts/Gotham-Black.woff') format('truetype');
}

@font-face {
  font-family: 'GothamBold';
  src: local('GothamBold'),
    url('./assets/fonts/Gotham-Bold.woff') format('truetype');
}

@font-face {
  font-family: 'GothamBook';
  src: local('GothamBook'),
    url('./assets/fonts/Gotham-Book.woff') format('truetype');
}

body {
  height: 100%;
  margin: 0;
  font-family: 'GothicBold', 'Arial';
  -webkit-font-smoothing: antialiased;
  background-color: #3b3b3b;
}

h1.uk-heading-small {
  color: #FFFFFF;
  font-family: 'GothamBold', 'Arial';
  line-height: normal;
}

.body-wrapper {
  display: flex;
  flex-flow: column;
  height: 100%;
}

div.uk-card .uk-card-body {
  padding-top: 0px;
}