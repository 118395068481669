button.submit-button {
    background-color: #3E6AB3;
    border-radius: 20px;
    text-transform: none;
    font-size: 15px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 20px;
}

input.uk-input, select.uk-select, textarea.uk-textarea, option, ::placeholder {
    background-color: #3B3B3B;
    color: #fff !important
}
input.uk-input:focus, select.uk-select:focus, textarea.uk-textarea:focus {
    background-color: #3B3B3B;
    border-color: #3E6AB3;
}
.submit {
    text-align: center;
}