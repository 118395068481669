.content {
  text-align: center;
  font-family: 'GothamBook';
  font-size: 14px;
  color: #8e8e8e;
}

.heading-emphasis {
  font-family: 'GothamBold';
  color: #3E6AB3;
}

.heading {
  font-family: 'GothamBold', 'Arial';
  color: #FFFFFF;
}

span.carousel-emphasis {
  font-family: 'GothamBlack', 'Arial';
}

.special {
  font-family: 'GothamBold', 'Arial';
  color: #8e8e8e
}

.text {
  color: #8e8e8e;
}

.text-dark {
  color: #3B3B3B;
}

button.call-to-action {
  background-color: #3E6AB3;
  border-radius: 20px;
  text-transform: none;
  font-size: 15px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 20px;
}

.section-white {
  background-color: #FFF;
  color: #3B3B3B;
}

.section-white > div > h1.heading {
  color: #3B3B3B;
}

.no-margins {
  margin-top: 0px;
  margin-bottom: 0px;
}

.heading-no-margin {
  margin-top: 0px !important;
}
.stars {
  color: #3B3B3B;
}
.image-workstation {
  width: 75%;
}
.image-nas {
  width: 80%;
}
.joe-image {
  max-height: 320px;
}
.center-align {
  text-align: center;
}
p, .left-align {
  text-align: left;
}
div.mac-margins {
  margin-bottom: 0px;
}

span.carousel-heading {
  font-family: 'GothamBlack', 'Arial';
  font-size: 7vw;
}
span.tagline-text {
  color: white;
  font-weight: 600;
  font-size: 3vw;
}

@media all and (max-width: 768px) {
  span.carousel-heading {
    font-size: 14vw;
  }
  span.tagline-text {
    font-size: 7vw;
  }
}

.client-logo {
  padding-top: 5px;
  padding-bottom: 5px;
}

.ppgroup-logo {
  padding-top: 20px;
}

.image-height {
  height: 250px;
  min-height: 55vh
}

