div.uk-card.uk-card-secondary {
    background-color: #3B3B3B;
}

svg.card-icon {
    color: #FFFFFF
}

h3.uk-card-title.uk-margin-remove-bottom {
    font-family: 'GothamBlack', 'Arial';
    line-height: normal;
    color: #3E6AB3 !important;
}

div.uk-card-body.card-body {
    color: #818181;
}

p.heading-text {
    color: #818181;
}

a.links {
    color: #3E6AB3 !important;
}

p.uk-text-lead {
    color: #818181;
}

h2.uk-heading-x-small {
    font-family: 'GothamBlack', 'Arial';
    line-height: normal;
    color: #FFFFFF;
}

div.uk-card.uk-card-secondary {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }