.image-wrapper {
    text-align: center;
}

.image {
    max-height: 670px;
}

.photo-label {
    margin-top: 0px;
    margin-right: 10px;
    text-align: right;
}