p.joe-title {
    font-size: 24px;
}

img.photo {
    border-radius: 20px;;
}

h3.vision-statement {
    font-family: 'GothamBlack', 'Arial';
    line-height: normal;
    color: #818181 !important;
}

div.uk-card-body.about-body {
    color: #818181;
}