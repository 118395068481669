.footer-wrapper {
    color: #818181;
    background-color: #3B3B3B;
    width: 100%;
}

.padding-fb {
    padding-top: 35px;
}

.padding-copy {
    padding-top: 8px;
}

a.links svg {
    color: #FFFFFF;
}

a.links {
    color: #3E6AB3;
}

img.heroku-icon {
    top: -8px;
    position: relative;
}