button.submit-button {
    background-color: #3E6AB3;
    border-radius: 20px;
    text-transform: none;
    font-size: 15px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 20px;
}

h3.inverted-text, label.inverted-text, ul.inverted-text, p.inverted-text {
    background-color: #3B3B3B;
    color: #fff; 
}

input.uk-input:focus, select.uk-select:focus, textarea.uk-textarea:focus {
    background-color: #3B3B3B;
    border-color: #3E6AB3;
}
.submit {
    text-align: center;
}
.speedtest {
    border: 1px solid #fff;
}

.st-checkbox {
    width: 150px;
}